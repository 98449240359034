import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import Content from '../../components/content';
import Icon from '../../layout/icons';

function getColor({ value }) {
  if (value >= 1) {
    return 'rgba(165, 196, 116, 0.1)';
  }
  return 'rgba(217, 88, 84, 0.1)';
}

const Metric = ({
  value,
  name,
  displayValue = value,
  description,
}) => {
  const [open, setOpen] = useState(false);
  const toggle = useCallback(() => {
    setOpen(!!description && !open);
  }, [description, open]);

  return (
    <MetricBox value={value} open={open}>
      <Top onClick={toggle} hasDescription={!!description}>
        <Name>
          {description && <MoreIcon icon="arrow-down" open={open} />}
          {' '}
          {name}
        </Name>
        <Value>{displayValue}</Value>
      </Top>
      <Description markdown content={description} />
    </MetricBox>
  );
};

const MetricBox = styled.div`
  background: ${getColor};
  border: none;
  border-radius: 3px;
  padding: 1rem;
  height: ${(props) => (props.open ? 'auto' : '1.1rem')};
  overflow: hidden;
`;

const Top = styled.button`
  background: none;
  border: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: ${(props) => (props.hasDescription ? 'pointer' : 'initial')};
  padding: 0;
  margin: 0;
`;

const Name = styled.span`
  font-family: ${(props) => props.theme.fontFamilyBold};
`;

const Description = styled(Content)`
  margin-top: 1rem;
  /* padding: 1rem; */
`;

const Value = styled.span`
`;

const MoreIcon = styled(Icon)`
  display: inline-block;
  width: 0.75rem;
  margin-right: 0.5rem;
  margin-bottom: 0.15rem;
  transition: transform 0.2s ease;
  transform: rotate(${(props) => (props.open ? 180 : 0)}deg);
`;

export default Metric;
