import React from 'react';
import styled from 'styled-components';
import {useScans} from '../../helpers/get-scan-results';
import Container from '../../components/container';
import Metric from './metric';
import i18n from '../../i18n';
import Loader from './loader';

const Metrics = () => {
  const { loading } = useScans(1);

  if (loading) {
    return <Loader />;
  }

  if (!loading) {
    return null;
  }

  // return; // This page is not currently loaded

  const {
    siege,
    lighthouse,
  } = {};

  if (!lighthouse) {
    return <></>;
  }

  const speedIndex = lighthouse.desktop.audits.find((o) => o.id === 'speed-index');
  const ttfb = lighthouse.desktop.audits.find((o) => o.id === 'server-response-time');
  const interactive = lighthouse.desktop.audits.find((o) => o.id === 'interactive');

  return (
    <section>
      <StyledContainer>
        <Grid>
          <Metric
            value={siege.failedTransactions > 0 ? 0 : 1}
            displayValue={`${siege.failedTransactions}`}
            name={i18n('metricFailedTransactions', 'Gefaalde transacties')}
            description={i18n('metricFailedTransactionsDescription')}
          />
          <Metric
            value={siege.successfulTransactions}
            name={i18n('metricSuccessfulTransactions', 'Succesvolle transacties')}
            description={i18n('metricSuccessfulTransactionsDescription')}
          />
          <Metric
            value={siege.transactionRate}
            name={i18n('metricTransactionRate', 'Transactie snelheid')}
            description={i18n('metricTransactionRateDescription')}
          />
          <Metric
            value={speedIndex.score}
            displayValue={speedIndex.displayValue}
            name={i18n('metricSpeedIndex', 'Snelheidsindex')}
            description={i18n('metricSpeedIndexDescription', 'De snelheidsindex meet hoe snel de inhoud visueel wordt weergegeven tijdens het laden van een pagina.')}
          />
          <Metric
            value={ttfb.score}
            displayValue={`${Math.round(ttfb.numericValue)} ms`}
            name={i18n('metricTtfb', 'Server reactie snelheid (TTFB)')}
            description={i18n('metricTtfbDescription', 'Time To First Byte (TTFB) is een metric die meet wat de reactietijd is van het HTTP verzoek tot aan de eerste byte die ontvangen wordt vanaf de webserver. Hier geldt hoe lager de response tijd is, des te beter dit is. Wil je meer weten over [TTFB](https://www.hipex.io/time-to-first-byte-ttfb/)?')}
          />
          <Metric
            value={interactive.score}
            displayValue={interactive.displayValue}
            name={i18n('metricInteractive', 'Time To Interactive')}
            description={i18n('metricInteractiveDescription', 'De TTI is de benodigde tijd die een webshop nodig heeft om in te laden tot het moment dat er sprake is van interactiviteit. Een interactieve pagina is een pagina waar je als gebruiker meer kunt doen dan enkel lezen en/of bekijken van content.')}
          />
        </Grid>
      </StyledContainer>
    </section>
  );
};

const Grid = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  /* align-items: center; */
  @media all and (min-width: ${(props) => props.theme.breakingpoints.sm}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const StyledContainer = styled(Container)`
  padding-bottom: 7.5rem;

  @media print {
    padding-top: 4rem;
    break-before: always;
    break-inside: avoid;
  }
`;

export default Metrics;
